import classNames from 'classnames';
import NewsletterHome from "../components/Newsletter/NewsletterHome";
import BoxPostMain from "../components/Posts/BoxPostMain";
import style from "./Category.module.scss";
import BoxSecondCategory from '../components/Posts/BoxSecondCategory';
import { useGetPostsQuery} from '../shared/generated/graphql';
import { useParams, useSearchParams } from 'react-router-dom';
import { Pagination } from '../components/Pagination/Pagination';
import { useEffect, useState } from 'react';

export function AllPosts() {
  const size = 10
  const {name} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const currentPage = parseInt(searchParams.get("page") || "1")

  const {data, fetchMore} = useGetPostsQuery({
    variables:{
      size,
      offset: size * (currentPage - 1)
    }
  })

  const featuredPosts = data?.posts?.nodes?.slice(0,4)
  const otherPosts = data?.posts?.nodes?.slice(4,undefined)

  useEffect(() => {
    fetchMore({
      variables:{
        size,
        offset: size * (currentPage - 1),
        categoryName: name
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return ({ ...fetchMoreResult })   
      }
    })
  }, [searchParams])

  return (
    <>
      
      <section className={classNames("container-fluid py-md-5 py-3", style.topo)}>
        <div className="container">
          <div className="row justify-content-md-around">
            <h2>Todos os posts do blog</h2> 
          </div>
        </div>
      </section>
      <section className={classNames("container-fluid py-md-5 py-3", style.mainPosts)}>
        <div className="container">
          <div className="row justify-content-md-around">
          {
            featuredPosts?.map(value => (
              <BoxPostMain
                authorName={value?.author?.node?.name || ""}
                avatarUrl={value?.author?.node?.avatar?.url || ""}
                category={value?.categories?.nodes?.[0]?.name || ""}
                categoryUrl={value?.categories?.nodes?.[0]?.uri || ""}
                content={value?.excerpt || ""}
                date={value?.date || ""}
                title={value?.title || ""}
                key={value?.id || ""}
                linkPost={value?.uri || ""}
              />
            ))
          }  
          </div>
        </div>
      </section>
      <section className={classNames("container-fluid py-md-5 py-3", style.secondPosts)}>
        <div className="container">
          <div className="row justify-content-center">
            {
            otherPosts?.map(value => (
              <BoxSecondCategory
              authorName={value?.author?.node?.name || ""}
              avatarUrl={value?.author?.node?.avatar?.url || ""}
              category={value?.categories?.nodes?.[0]?.name || ""}
              categoryUrl={value?.categories?.nodes?.[0]?.uri || ""}
              content={value?.excerpt || ""}
              date={value?.date || ""}
              title={value?.title || ""}
              key={value?.id || ""}  
              linkPost={value?.uri || ""}            
              />
              ))
            }  
            <div className={classNames("col-12", style.paginacaoPosts)}> 
              <Pagination
                  pages={Math.ceil((data?.posts?.pageInfo?.offsetPagination?.total || 1) / size)}
                />
            </div>
          </div>
        </div>
      </section>
      <NewsletterHome />      
    </>
  )
}