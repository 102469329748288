import classNames from 'classnames';
import Button from '../components/Buttons/Buttons';
import style from './Obrigado.module.scss'


export function Obrigado() {
  return (
    <>      

    <section className={classNames('container d-flex align-items-center justify-content-center', style.secaoObrigado)}>
      <div className="row justify-content-center text-center">
        <h1>Agradecemos seu interesse!</h1>
        <p>Em breve entraremos em contato.</p>
        <Button className={style.cta} href='https://ingagedigital.com.br/clientes-cases' target="_blank">Veja nossos cases</Button>
      </div>
    </section>
      
    </>
  )

}