import classNames from "classnames";
import { useQuery, gql } from "@apollo/client";
import BoxPostMain from "../components/Posts/BoxPostMain";
import BoxPostSecond from "../components/Posts/BoxPostSecond";
import style from "./Home.module.scss";
import NewsletterHome from "../components/Newsletter/NewsletterHome";
import BoxConteudo from "../components/Posts/BoxConteudo";
import { SunspotLoader } from "react-awesome-loaders";
import { Helmet } from "react-helmet"; // Adicionando Helmet diretamente aqui para o SEO

const GET_POSTS = gql`
  query GetPosts($first: Int!, $after: String) {
    posts(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        title
        uri
        excerpt
        date
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const ALLOWED_CATEGORIES = ["Growth", "Inbound", "Conteúdo", "Social Media"];

interface Post {
  uri: string;
  title: string;
  excerpt: string;
  date: string;
  featuredImage?: {
    node?: {
      sourceUrl: string;
      altText: string;
    };
  };
  author?: {
    node?: {
      name: string;
      avatar?: {
        url: string;
      };
    };
  };
  categories?: {
    nodes: {
      name: string;
      uri?: string;
    }[];
  };
}

interface Category {
  name: string;
  uri?: string;
}

export function Home() {
  const { data, loading, error, fetchMore } = useQuery(GET_POSTS, {
    variables: {
      first: 80,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadMorePosts = () => {
    if (data?.posts.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.posts.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          return {
            posts: {
              __typename: previousResult.posts.__typename,
              nodes: [
                ...previousResult.posts.nodes,
                ...fetchMoreResult.posts.nodes,
              ],
              pageInfo: fetchMoreResult.posts.pageInfo,
            },
          };
        },
      });
    }
  };

  if (loading) {
    return (
      <div
        className={classNames(
          "container-fluid justify-content-center d-flex py-5",
          style.loaderContainer
        )}
      >
        <SunspotLoader
          gradientColors={["#D8365E", "#A0224A"]}
          shadowColor={"#70122F"}
          desktopSize={"128px"}
          mobileSize={"100px"}
        />
      </div>
    );
  }

  if (error) return <div>Ocorreu um erro ao carregar os dados.</div>;

  // Filtrar posts pelas categorias permitidas
  const filteredPosts = data?.posts.nodes.filter((post: Post) =>
    post.categories?.nodes.some((cat) => ALLOWED_CATEGORIES.includes(cat.name))
  );

  // Selecionar o último post válido de uma das categorias permitidas
  const latestPost = filteredPosts?.[0];

  // Remover o último post da lista de posts subsequentes
  const remainingPosts = filteredPosts?.filter(
    (post: Post) => post.uri !== latestPost?.uri
  );

  // Remover duplicatas de categorias, mantendo apenas as permitidas
  const categories = remainingPosts?.reduce(
    (uniqueCategories: Category[], post: Post) => {
      post.categories?.nodes.forEach((category) => {
        if (
          ALLOWED_CATEGORIES.includes(category.name) &&
          !uniqueCategories.find((cat) => cat.name === category.name)
        ) {
          uniqueCategories.push(category);
        }
      });
      return uniqueCategories;
    },
    []
  );

  return (
    <>
      {/* Adicionando o SEO padrão aqui */}
      <Helmet>
        <title>Blog de marketing digital | INGAGE</title>
        <meta
          name="description"
          content="Conheça o blog de marketing digital da INGAGE! Tudo sobre o universo do marketing com a curadoria de especialistas de cada área da agência!"
        />
        <meta
          name="keywords"
          content="marketing digital, inbound, outbound, growth, conteúdo, social media"
        />
        <meta
          property="og:title"
          content="Blog de marketing digital | INGAGE"
        />
        <meta
          property="og:description"
          content="Conheça o blog de marketing digital da INGAGE! Tudo sobre o universo do marketing com a curadoria de especialistas de cada área da agência!"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={latestPost?.featuredImage?.node?.sourceUrl || ""}
        />
      </Helmet>

      {/* Seção onde só o último post é exibido */}
      <section className={classNames("container-fluid", style.secaoInicial)}>
        <div className={classNames("container", style.latestPost)}>
          <h1>
            A INGAGE é especialista em ajudar médias e grandes empresas de
            diversos segmentos, por meio de estratégias inbound e outbound,
            integrando marketing e vendas.
          </h1>

          {latestPost && (
            <BoxPostMain
              linkPost={latestPost.uri}
              authorName={latestPost.author?.node?.name}
              avatarUrl={latestPost.author?.node?.avatar?.url}
              category={latestPost.categories?.nodes?.[0]?.name}
              categoryUrl={latestPost.categories?.nodes?.[0]?.uri}
              content={latestPost.excerpt}
              date={latestPost.date}
              title={latestPost.title}
              featuredImageUrl={latestPost.featuredImage?.node?.sourceUrl}
              key={latestPost.uri}
            />
          )}
        </div>
      </section>

      <section className="container-postagens container py-5 py-3">
        {categories?.map((category: Category, index: number) => {
          // Filtrar os posts que pertencem a essa categoria
          const postsInCategory = remainingPosts?.filter((post: Post) =>
            post.categories?.nodes.some((cat) => cat.name === category.name)
          );

          // Garantir que haja pelo menos 3 posts para exibir
          if (postsInCategory.length > 0) {
            return (
              <div key={category.name} className="py-md-5">
                <h2 className={classNames("text-center mb-5", style.subtitulo)}>
                  {category.name}
                </h2>

                <div className="row justify-content-md-around">
                  {/* Primeiro post como BoxPostSecond */}
                  <div className="col-md-5">
                    {postsInCategory[0] && (
                      <BoxPostSecond
                        title={postsInCategory[0].title}
                        category={category.name}
                        categoryUrl={category.uri || ""}
                        content={postsInCategory[0].excerpt}
                        thumbUrl={
                          postsInCategory[0].featuredImage?.node?.sourceUrl ||
                          ""
                        }
                        url={postsInCategory[0].uri}
                        key={postsInCategory[0].uri}
                      />
                    )}
                  </div>

                  {/* Próximos dois posts como BoxPostMain */}
                  <div className="col-md-7">
                    <div className="row flex-column">
                      {postsInCategory.slice(1, 3).map((post: Post) => (
                        <div className="col-md-12" key={post.uri}>
                          <BoxPostMain
                            linkPost={post.uri}
                            authorName={post?.author?.node?.name || ""}
                            avatarUrl={post?.author?.node?.avatar?.url || ""}
                            category={category.name}
                            categoryUrl={category.uri || ""}
                            content={post.excerpt}
                            date={post.date}
                            title={post.title}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Alternar entre BoxConteudo e NewsletterHome a cada 2 categorias */}
                {index % 4 === 1 && (
                  <div className="my-5">
                    <BoxConteudo />
                  </div>
                )}
                {index % 4 === 3 && (
                  <div className="my-5">
                    <NewsletterHome />
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}
      </section>
    </>
  );
}
