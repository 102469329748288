import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import style from './Posts.module.scss'
import imagemConteudo from '../../images/img-conteudo.png'
import Button from '../Buttons/Buttons'


export default function BoxConteudo() {
  return ( 
    
    <div className={classNames("d-flex p-5 align-items-center", style.boxConteudo)}>
      <div className="col-md-4 d-flex justify-conten-center">        
        <img src={imagemConteudo} alt="imagem post" width="100%" className={style.thumb} />
      </div>
      <div className="col-md-8">        
        <div className={style.categoria}>
          <a href="">Infográfico</a>
        </div>
        <div className={style.texto}>
          <h2>Checklist de SEO</h2>
          <p>Utilize a mesma metodologia que gerou milhões em tráfego e vendas para os nossos clientes.</p>
        </div>
        <Button href='https://materiais.ingagedigital.com.br/checklist-de-seo' target="_blank" className={style.cta} >Baixar agora </Button>
      </div>
    </div>
    
  )
}