import classNames from "classnames";
import { AnchorHTMLAttributes, ReactNode } from "react";
import style from "./Posts.module.scss";
import { Link } from "react-router-dom";
import { toLocaleDate } from "../../shared/utils/ToLocaleDate";
import { CATEGORIES_COLORS } from "../../shared/constants";

type Props = {
  linkPost: string;
  title: string;
  category: string;
  categoryUrl: string;
  content: string;
  date: string;
  avatarUrl: string;
  authorName: string;
  featuredImageUrl?: string; // Adicionar esta linha
};

export default function BoxPostMain({
  authorName,
  avatarUrl,
  category,
  categoryUrl,
  content,
  date,
  title,
  linkPost,
  featuredImageUrl, // Recebe a imagem de destaque
}: Props) {
  return (
    <div
      className={classNames(" mb-2 py-5 px-5 ", style.box, style.boxPostMain)}
    >
      <Link to={linkPost} className={style.cardLink}></Link>

      {featuredImageUrl && (
        <img
          src={featuredImageUrl}
          alt={title}
          className={style.featuredImage}
        />
      )}
      <div>
        <div className={style.categoria}>
          <Link
            style={{ backgroundColor: CATEGORIES_COLORS[category] }}
            to={categoryUrl}
          >
            {category}
          </Link>
        </div>
        <div className={style.texto}>
          <h2>{title}</h2>
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>
        <div className={classNames("d-flex align-items-center", style.info)}>
          <div className={classNames("d-flex align-items-center", style.autor)}>
            <img src={avatarUrl} alt="imagem autor" />
            <p>{authorName}</p>
          </div>
          •
          <div className={style.dataPostagem}>
            {!!date && <p>{toLocaleDate(date || "")}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
