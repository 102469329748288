import classNames from "classnames";
import { Link } from "react-router-dom";
import style from "./Posts.module.scss";
import { CATEGORIES_COLORS } from "../../shared/constants";

type Props = {
  title: string;
  category: string;
  categoryUrl: string;
  content: string;
  thumbUrl?: string;
  url: string;
};

export default function BoxPostSecond({
  category,
  categoryUrl,
  content,
  title,
  thumbUrl,
  url,
}: Props) {
  return (
    <div
      className={classNames(
        "d-flex flex-column align-items-start mb-3 me-3 py-5 px-5",
        style.boxSecond
      )}
    >
      {thumbUrl && (
        <Link to={url}>
          <img
            src={thumbUrl}
            alt="imagem post"
            width="100%"
            className={classNames("mb-4", style.thumbBoxSecond)}
          />
        </Link>
      )}

      <div className={style.categoria}>
        {/* Mantemos o link da categoria separado */}
        <Link
          style={{ backgroundColor: CATEGORIES_COLORS[category] }}
          to={categoryUrl}
        >
          {category}
        </Link>
      </div>

      <div className={style.texto}>
        {/* Colocamos o título como um link */}
        <h2 className={style.linkTitle}>{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    </div>
  );
}
