import classNames from 'classnames'
import style from './ShareButtons.module.scss'
import {ReactComponent as LogoTwitter} from '../../images/twitter-share-icon.svg'
import {ReactComponent as LogoLinkedin} from '../../images/linkedin-share-icon.svg'
import {ReactComponent as LogoFacebook} from '../../images/fb-share-icon.svg'

type Props = {
  url : string,
}

export function ShareButtons({url} : Props) {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const twitterUrl = `https://twitter.com/intent/tweet?text=${url}`
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
  return(    
    <div className={classNames("d-flex", style.shareButtons)}>
    <a href={facebookUrl} target="_blank" className={style.fb}><LogoFacebook /></a>
    <a href={twitterUrl} target="_blank" className={classNames("mx-2", style.tt)}><LogoTwitter /></a>
    <a href={linkedinUrl} target="_blank" className={style.ln}><LogoLinkedin /></a>
  </div>
  )
}