import classNames from "classnames";
import { AnchorHTMLAttributes, ReactNode } from "react";
import style from "./Posts.module.scss";
import { Link } from "react-router-dom";
import { toLocaleDate } from "../../shared/utils/ToLocaleDate";

type Props = {
  title: string;
  category: string;
  categoryUrl: string;
  content: string;
  date: string;
  avatarUrl: string;
  authorName: string;
  linkPost?: string;
};

export default function BoxSecondCategory({
  authorName,
  avatarUrl,
  category,
  categoryUrl,
  content,
  date,
  title,
  linkPost,
}: Props) {
  return (
    <div
      className={classNames(
        "col-md-12 d-flex flex-column   py-md-5 py-3 px-md-5",
        style.box
      )}
    >
      <Link to={linkPost || "#"} className={style.cardLink}></Link>
      <div className={style.categoria}>
        <Link to={categoryUrl}>{category}</Link>
      </div>
      <div className={style.texto}>
        <h2>{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
      <div className={classNames("d-flex ", style.info)}>
        <div className={classNames("d-flex ", style.autor)}>
          <img src={avatarUrl} alt="imagem autor" />
          <p>{authorName}</p>
        </div>
        •
        <div className={style.dataPostagem}>
          <p>{toLocaleDate(date)}</p>
        </div>
      </div>
    </div>
  );
}
