import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import style from './Posts.module.scss'


export default function BoxContato() {
  return ( 
    
    <div className="container pt-md-5">
      <div className="row">
        <div className={classNames("col-md-5 p-md-5 p-3 me-md-5", style.boxContato)}>
          <h2>Agência referência de marketing digital</h2>
          <p>Somos especialistas em trabalhar com empresas de vendas complexas B2B.</p>
          <a href="https://ingagedigital.com.br" target="_blank" className={style.cta}>Conheça a Ingage</a>
        </div>
        <div className={classNames("col-md-5 p-md-5 p-3", style.boxContato)}>
          <h2>Receba uma proposta comercial</h2>
          <p>Soluções personalizadas com foco no relacionamento e retorno financeiro para nossos clientes.</p>
          <a href="https://ingagedigital.com.br/fale-com-um-consultor" target="_blank" className={style.cta}>Fale com um consultor</a>
        </div>
      </div>
    </div>
    
  )
}