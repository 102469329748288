import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import styleFooter from './Footer.module.scss'
import Button from '../Buttons/Buttons'
import { Link } from 'react-router-dom'
import logoIngage from '../..//images/logo.svg'
import logoTwitter from '../../images/twitter-icon.svg'
import logoLinkedin from '../../images/linkedin-icon.svg'
import logoInstagram from '../../images/instagram-icon.svg'
import logoFacebook from '../../images/facebook-icon.svg'

export default function Footer() {
 return (
   <footer className={classNames("container-fluid py-md-5 py-3", styleFooter.footer)}>
     <div className="container">
       <div className="row">
         <div className={classNames("col-md-5 d-flex flex-column", styleFooter.infos)}>
            <img src={logoIngage} alt="Logo INGAGE" className="d-inline-block align-text-top logo" />
            <p>Rua Divinópolis, 293, Santa Tereza <br /> Belo Horizonte - MG</p>
            <p>Telefone: (31) 3370-8501</p>
            <p>E-mail: contato@ingagedigital.com.br</p>
            <p>M4L MARKETING LTDA <br />
            CNPJ 21.125.961/0001-00</p>
            <a href="https://ingagedigital.com.br/politica-de-privacidade" target="_blank">Política de Privacidade</a>
            <div className={classNames("d-flex py-3", styleFooter.redesSociais)}>
              <a href="https://twitter.com/ingagedigital" target="_blank"><img src={logoTwitter} alt="ícone twitter" /></a>
              <a href="https://www.linkedin.com/company/ingage-digital-branding/mycompany/" target="_blank"><img src={logoLinkedin} alt="ícone linkedin" /></a>
              <a href="https://www.instagram.com/ingagedigital/?hl=pt-br" target="_blank"><img src={logoInstagram} alt="ícone instagram" /></a>
              <a href="https://pt-br.facebook.com/ingagedigital" target="_blank"><img src={logoFacebook} alt="ícone facebook" /></a>
            </div>
            <Button href="https://ingagedigital.com.br/fale-com-um-consultor" target="_blank" className={styleFooter.cta}>Fale com um consultor</Button>
         </div>
         <div className={classNames("col-md-3 d-flex flex-column", styleFooter.menu)}>
          <h3>Tópicos</h3>
          <Link to="/categorias/cases">Cases</Link>
          <Link to="/categorias/conteudo">Conteúdo</Link>
          <Link to="/categorias/growth">Growth</Link>
          <Link to="/categorias/inbound">Inbound</Link>
          <Link to="/categorias/inbound-sales">Inbound sales</Link>
          <Link to="/categorias/links-patrocinados-e-ads">Links Patrocinados</Link>
          <Link to="/categorias/outros">Outros</Link>
          <Link to="/categorias/seo">SEO</Link>
          <Link to="/categorias/social-media">Social Media</Link>
         </div>
         <div className={classNames("col-md-4 d-flex flex-column", styleFooter.menu)}>
          <h3>Mapa do site</h3>
          <a href="https://ingagedigital.com.br/empresa" target="_blank">A Ingage</a>
          <a href="">Soluções</a>
          <div className={classNames("d-flex flex-column", styleFooter.submenu)}>
            <a href="https://ingagedigital.com.br/inbound-marketing" target="_blank">Inbound Marketing</a>
            <a href="https://ingagedigital.com.br/gestao-de-midia" target="_blank">Gestão de Mídia e Performance</a>
            <a href="https://ingagedigital.com.br/criacao-de-sites" target="_blank">Sites de Alta Performance</a>
            <a href="https://ingagedigital.com.br/marketing-para-saas" target="_blank">Marketing para SaaS</a>
          </div>
          <a href="" target="_blank">Clientes e Cases</a>
          <a href="">Conteúdo</a>
          <div className={classNames("d-flex flex-column", styleFooter.submenu)}>
            <Link to="/">Blog</Link>
            <a href="https://ingagedigital.com.br/central-estudos" target="_blank">Central de Estudos</a>
            <a href="https://ingagedigital.com.br/core-web-vitals" target="_blank">Core Web Vitals</a>
          </div>
          <a href="https://ingagedigital.com.br/carreiras" target="_blank">Carreiras</a>
          <a href="https://ingagedigital.com.br/fale-com-um-consultor" target="_blank">Contato</a> 
        </div>
       </div>
     </div>
   </footer>
 )
}