import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./templates/Home";
import { Category } from "./templates/Category";
import { Post } from "./templates/Post";
import { Materials } from "./templates/Materials";
import { Layout } from "./components/Layout";
import { ApolloProvider } from "@apollo/client";
import { initializeApollo } from "./apollo-client";
import { Search } from "./templates/Search";
import { AllPosts } from "./templates/AllPosts";
import { Obrigado } from "./templates/Obrigado";

declare global {
  interface Window {
    RDStationForms: any;
  }
}

function App() {
  const apolloClient = initializeApollo();

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/categorias/:name" element={<Category />} />
              <Route path="/:slug" element={<Post />} />{" "}
              {/* Passagem do slug na rota dinâmica */}
              <Route path="/materiais-ricos" element={<Materials />} />
              <Route path="/busca" element={<Search />} />
              <Route path="/todos-os-posts" element={<AllPosts />} />
              <Route path="/obrigado" element={<Obrigado />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
