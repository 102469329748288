import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import styleButton from './Buttons.module.scss'

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: ReactNode,
  className?: string,
  target?: string,
  alert?: () => void,
  variante?: "primario" | "secundario",
}

export default function Button(props: Props) {
  const {children, className, alert, target, href, variante = ""} = props 
  return(<> <a onClick={() => alert?.() } className={classNames(className, styleButton.cta, styleButton[variante])} target={target} href={href}>{children}</a></>)
}