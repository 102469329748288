import { ReactNode, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Footer from "./Footer/Footer"
import Header from "./Header/Header"

export function Layout(){

  const {pathname} = useLocation ()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header/>
        <Outlet/>
      <Footer/>
    </>
  )

}