import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import style from './Posts.module.scss'
import Button from '../Buttons/Buttons'

type Props = {
  title : string,
  category : string,
  content : string,
  thumbUrl? : string,
  url : string,
}

export default function BoxConteudoMaterial({category, content, title, thumbUrl, url} : Props) {
  return ( 
    
    <div className={classNames("d-flex p-5 align-items-center", style.boxConteudo)}>
      <div className="col-md-4 d-flex justify-conten-center">        
        <img src={thumbUrl} alt="imagem post" width="100%" className={style.thumb} />
      </div>
      <div className="col-md-8">        
        <div className={style.categoria}>
          <a href="">{category}</a>
        </div>
        <div className={style.texto}>
          <h2>{title}</h2>
          <p dangerouslySetInnerHTML={{__html:content}}></p>
        </div>
        <Button href={url} target="_blank" className={style.cta} >Baixar agora </Button>
      </div>
    </div>
    
  )
}