import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode } from 'react'
import style from './Posts.module.scss'
import { Link } from 'react-router-dom'
import Button from '../Buttons/Buttons'

type Props = {
  title : string,
  category : string,
  content : string,
  thumbUrl? : string,
  url : string,
}

export default function BoxMaterial({category, content, title, thumbUrl, url} : Props) {
  return (
    <a href={url} target="_blank" className={classNames("col-md-6 d-flex flex-column align-items-start mb-3 p-md-5 p-3", style.boxMaterial)}>            
      <img src={thumbUrl} alt="imagem post" width="100%" className={style.thumb} />
      <div className="text">
        <div className={style.categoria}>
          <Link to="">{category}</Link>
        </div>
        <div className={style.texto}>
          <h2>{title}</h2>
          <p dangerouslySetInnerHTML={{__html:content}}></p>
        </div>
        <Button>Baixar agora </Button>
      </div>
    </a>
  )
}