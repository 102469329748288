import BoxContato from "../components/Posts/BoxContato";
import classNames from "classnames";
import style from "./Single.module.scss";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import Newsletter from "../components/Newsletter/Newsletter";
import { useGetPostBySlugQuery } from "../shared/generated/graphql";
import { toLocaleDate } from "../shared/utils/ToLocaleDate";
import { Skeleton } from "../components/Skeleton/Skeleton";
import { ShareButtons } from "../components/ShareButtons/ShareButtons";
import { Seo } from "../components/Seo";

declare global {
  interface Window {
    RDStationForms: any;
  }
}

export function Post() {
  useEffect(() => {
    const initRDStationForms = () => {
      const formElement = document.getElementById(
        "assinar-newsletter-ff96609754dd4d610913"
      );

      // Verifique se o elemento do formulário existe antes de tentar anexar o formulário
      if (window.RDStationForms && formElement) {
        new window.RDStationForms(
          "assinar-newsletter-ff96609754dd4d610913",
          "UA-40351796-1"
        ).createForm();
      } else {
        console.error(
          "Formulário RDStation ou elemento de destino não encontrados."
        );
      }
    };

    // Se o RDStationForms não estiver disponível, carregue o script dinamicamente
    if (typeof window.RDStationForms === "undefined") {
      const script = document.createElement("script");
      script.src =
        "https://d335luupugsy2.cloudfront.net/js/rdstation-forms.min.js";
      script.async = true;
      script.onload = initRDStationForms;
      document.body.appendChild(script);
    } else {
      initRDStationForms();
    }
  }, []);

  const { slug } = useParams(); // Obtém o slug da URL
  const { data, loading } = useGetPostBySlugQuery({
    variables: { slug: slug as string },
  });
  const url = window.location.href;

  if (loading) {
    return <Skeleton total={30} />; // Exibe um carregamento enquanto os dados são buscados
  }

  return (
    <>
      {/* Passa o slug para o componente Seo */}
      <Seo slug={slug as string} />

      <section
        className={classNames("container-fluid py-md-5 py-3", style.topoSingle)}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className={style.categoria}>
              <Link to={data?.post?.categories?.nodes?.[0]?.uri || ""}>
                {data?.post?.categories?.nodes?.[0]?.name}
              </Link>
            </div>
            <div className={style.texto}>
              <h1>{!!data?.post?.title ? data?.post?.title : <Skeleton />}</h1>
            </div>
            <div
              className={classNames(
                "d-flex justify-content-center align-items-center",
                style.info
              )}
            >
              <div className={style.autor}>
                <p>{data?.post?.author?.node?.name}</p>
              </div>
              •
              <div className={style.dataPostagem}>
                <p>{toLocaleDate(data?.post?.date || "")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container py-md-5 py-3">
        <div className="row justify-content-md-between">
          <div className={classNames("col-md-8", style.postagem)}>
            {!!data?.post?.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: data?.post?.content || "" }}
              ></div>
            ) : (
              <Skeleton total={30} />
            )}
          </div>
          <div
            className={classNames("col-md-3 d-flex flex-column", style.sidebar)}
          >
            <div className={classNames("d-flex flex-column", style.autor)}>
              <img
                src={data?.post?.author?.node?.avatar?.url || ""}
                alt="imagem autor"
                width="60px"
              />
              <h5>{data?.post?.author?.node?.name}</h5>
              <p>{data?.post?.author?.node?.description}</p>
            </div>
            <div className="d-flex flex-column mb-4">
              <h5>Compartilhe</h5>
              <ShareButtons url={url} />
            </div>
            <div className={style.secaoNewsletter} id="newsletter">
              <h5>Inscreva-se na newsletter</h5>
              <p>Inscreva-se para receber os últimos artigos do nosso blog.</p>
              <div
                role="main"
                className="formSidebar"
                id="assinar-newsletter-ff96609754dd4d610913"
              >
                <Newsletter />
              </div>
            </div>
          </div>
        </div>
        <BoxContato />
      </section>
    </>
  );
}
