import classNames from 'classnames'
import { AnchorHTMLAttributes, ReactNode, useState } from 'react'
import styleHeader from './Header.module.scss'
import Button from '../Buttons/Buttons'
import Menu from '../Menu/Menu'
import { Link } from 'react-router-dom'
import {ReactComponent as Logo } from '../../images/logo.svg'
import iconeSearch from '../../images/search.svg'
import { Searchform } from '../Search/Searchform'
import Collapse from 'react-bootstrap/Collapse'



export default function Header() {
  
  const [open, setOpen] = useState(false);
 
  return ( 

    <header className={classNames(styleHeader.header)}>
      <nav className="navbar navbar-expand-lg">
        <div className={classNames("container py-3 py-md-4", styleHeader.container)}>
          <Link to="/" className={classNames("navbar-brand", styleHeader.logo)}>
            <Logo />
            <span>|</span> Blog
          </Link>
          <Menu />
          <button onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open} className={classNames(styleHeader.search)}><img src={iconeSearch} alt="ícone search"/></button>
          <Button className={styleHeader.cta} href='#newsletter'>Assine a newsletter</Button>
        </div>
      </nav>
      
      
      <Collapse in={open}>
      <div className={classNames("container py-2")} id="example-collapse-text">
        <div className="row justify-content-end">          
          <Searchform />
        </div>
      </div>
      </Collapse>

      



      {/* <Button className={classNames(styleHeader.cta)}>
        Clique
      </Button> */}

    </header>
  )
}