import classNames from "classnames";
import { useEffect, useRef } from "react";
import style from "./Newsletter.module.scss";
import Newsletter from "./Newsletter";

export default function NewsletterHome() {
  return (
    <section
      id="newsletter"
      className={classNames("container p-md-5 p-3 my-md-5 my-3", style.boxNews)}
    >
      <div className="row justify-content-center">
        <div className="col-md-5 d-flex flex-column align-items-center text-center">
          <span>Newsletter</span>
          <h3>Inscreva-se</h3>
          <p>Inscreva-se para receber os últimos artigos do nosso blog.</p>
          {/* <Newsletter /> */}
          <Newsletter />
        </div>
      </div>
    </section>
  );
}
