import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet";
const GET_POST_SEO = gql`
  query GetPostSeo($slug: ID!) {
    post(id: $slug, idType: SLUG) {
      seo {
        title
        metaDesc
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;

type SeoProps = {
  slug: string;
};

export function Seo({ slug }: { slug: string }) {
  const { data, loading, error } = useQuery(GET_POST_SEO, {
    variables: { slug },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const updateTitle = () => {
      if (data && data.post && data.post.seo) {
        document.title =
          data.post.seo.title || "Blog de marketing digital | INGAGE";
      } else {
        document.title = "Blog de marketing digital | INGAGE";
      }
    };

    updateTitle();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateTitle();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [data]);

  if (loading) {
    return null;
  }

  if (error) {
    console.error("Erro ao carregar dados de SEO:", error);
    return null;
  }

  const seoData = data?.post?.seo || {};

  const defaultMetaDesc =
    "Conheça o blog de marketing digital da INGAGE! Tudo sobre o universo do marketing com a curadoria de especialistas de cada área da agência!";

  return (
    <>
      <Helmet>
        {/* Meta tags, agora gerenciadas por react-helmet-async */}
        <meta
          name="description"
          content={seoData.metaDesc || defaultMetaDesc}
        />
        <meta name="keywords" content={seoData.metaKeywords || ""} />
        <meta
          property="og:title"
          content={
            seoData.opengraphTitle ||
            seoData.title ||
            "Blog de marketing digital | INGAGE"
          }
        />
        <meta
          property="og:description"
          content={
            seoData.opengraphDescription || seoData.metaDesc || defaultMetaDesc
          }
        />
        <meta
          property="og:url"
          content={seoData.opengraphUrl || window.location.href}
        />
        <meta
          property="og:image"
          content={seoData.opengraphImage?.sourceUrl || ""}
        />
      </Helmet>
    </>
  );
}
