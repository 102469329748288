import classNames from 'classnames'
import { AnchorHTMLAttributes, Children, ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Button from '../Buttons/Buttons'
import styleMenu from './Menu.module.scss'


export default function Menu() {

  const classes = classNames("nav-link", styleMenu.navLink)
  const menu = [ 
    {
      href : "/categorias/cases/",
      text : "Cases",
    },
    {
      href : "/categorias/conteudo/",
      text : "Conteúdo"
    },
    {
      href : "/categorias/growth/",
      text : "Growth"
    },
    {
      href : "/categorias/inbound/",
      text : "Inbound"
    },
    {
      href : "/materiais-ricos",
      text : "Materiais"
    },
  ]

  return (
    <>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
      <div className={classNames("navbar-nav", styleMenu.menu)}>
        {
          Children.toArray(menu.map( item => (<NavLink className={classes} to={item.href}>{item.text}</NavLink>) ))
        }
      </div>
    </div>
    </>
  )
}