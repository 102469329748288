import style from './Searchform.module.scss'


export function Searchform() { 

  return (
    <>
      <form role="search" method="get" action="/busca" className={style.searchform}>
        <input type="search" name="term" placeholder='O que você procura?' />
        <button type='submit' className={style.cta}>Buscar</button>
      </form>
    </>
  )
}