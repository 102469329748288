import style from './Dot.module.scss'
import classNames from 'classnames'

type Props = {
  value: number
  className?: string
  label?: string | number
  active?: boolean
  onClick: (value: number) => void
}

export function Dot({ onClick, value, active, label, className }: Props) {
  function handleClick(page: number) {
    onClick(page)
  }

  return (
    <a
      key={value}
      className={classNames(className, style.dot)}
      onClick={() => handleClick(value)}
    >
      { label || value }
    </a>
  )
}