import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { useMemo } from "react";

// Variável para armazenar a instância do ApolloClient
let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

// Função para criar um novo ApolloClient
function createApolloClient(): ApolloClient<NormalizedCacheObject> {    
    console.log('GraphQL Host:', process.env.REACT_APP_GRAPHQL_HOST);
    return new ApolloClient({
        link: new HttpLink({
            uri: process.env.REACT_APP_GRAPHQL_HOST || 'http://localhost:4000/graphql', // URL de fallback, se necessário
        }),
        cache: new InMemoryCache(),
    });
}

// Função para inicializar o ApolloClient
export function initializeApollo(initialState: NormalizedCacheObject | null = null): ApolloClient<NormalizedCacheObject> {
    const _apolloClient = apolloClient ?? createApolloClient();

    // Reidratar o cache com dados iniciais, se fornecido
    if (initialState) {
        const existingCache = _apolloClient.extract();
        _apolloClient.cache.restore({ ...existingCache, ...initialState });
    }

    // Sempre retorne uma nova instância no servidor
    if (typeof window === "undefined") return _apolloClient;

    // Crie a instância do ApolloClient no client-side apenas uma vez
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}

// Hook para usar o ApolloClient
export function useApollo(initialState: NormalizedCacheObject | null): ApolloClient<NormalizedCacheObject> {
    const store = useMemo(() => initializeApollo(initialState), [initialState]);
    return store;
}
