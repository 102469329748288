import style from "./Skeleton.module.scss"
import classNames from "classnames";

type Props = {
  height?: string,
  width?: string,
  total?: number,
  image?: boolean,
}

export function Skeleton({width, height, total = 1, image} : Props) {

  const totalSkeleton = new Array(total).fill('');

  return (
    <>
      {
        totalSkeleton.map((_, index) => <div className={classNames(style.skeleton, {[style.image]:image})} key={`skeleton-${index}`} style={{width, height}}></div>)
      }       
    </>
  )
}