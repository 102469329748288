import classNames from 'classnames';
import style from './Materials.module.scss'
import NewsletterHome from '../components/Newsletter/NewsletterHome';
import BoxMaterial from '../components/Posts/BoxMaterial';
import { useGetMaterialsQuery } from '../shared/generated/graphql';
import BoxConteudoMaterial from '../components/Posts/BoxConteudoMaterial';


export function Materials() {
  const {data} = useGetMaterialsQuery()  
  // console.log(data)
  const featuredMaterial = data?.featured?.nodes?.[0]
  return (
    <>      
      <section className={classNames("container-fluid py-md-5 py-3", style.topo)}>
        <div className="container">
          <div className="row">
            <BoxConteudoMaterial 
              url={featuredMaterial?.customFieldMateriais?.linkDestino || ""}               
              category={featuredMaterial?.categorias?.nodes?.[0]?.name || ""}
              content={featuredMaterial?.content || ""}                
              title={featuredMaterial?.title || ""}
              thumbUrl = {featuredMaterial?.featuredImage?.node?.sourceUrl || ""} 
            />    
          </div>
        </div>
      </section>
      <section className={classNames("container py-md-5 py-3", style.secaoCategoria)}>
        <div className="row">
          <h3>Inbound Marketing</h3>
        </div>    
      </section>
      <section className="container">
        <div className="row justify-content-md-between">
          { data?.inbound?.nodes?.map((material) => (                         
              <BoxMaterial 
                url={material?.customFieldMateriais?.linkDestino || ""}               
                category={material?.categorias?.nodes?.[0]?.name || ""}
                content={material?.content || ""}                
                title={material?.title || ""}
                thumbUrl = {material?.featuredImage?.node?.sourceUrl || ""} 
                key = {material?.id}
              /> 
            ))         
          }
        </div>
      </section>
      <section className={classNames("container py-md-5 py-3", style.secaoCategoria)}>
        <div className="row">
          <h3>Marketing Digital</h3>
        </div>    
      </section>
      <section className="container">
        <div className="row justify-content-md-between">
          { data?.marketingDigital?.nodes?.map((material) => (                         
              <BoxMaterial 
                url={material?.customFieldMateriais?.linkDestino || ""}               
                category={material?.categorias?.nodes?.[0]?.name || ""}
                content={material?.content || ""}                
                title={material?.title || ""}
                thumbUrl = {material?.featuredImage?.node?.sourceUrl || ""} 
                key = {material?.id}
              /> 
            ))         
          }
        </div>
      </section>
      <section className={classNames("container py-md-5 py-3", style.secaoCategoria)}>
        <div className="row">
          <h3>Clientes e Cases</h3>
        </div>    
      </section>
      <section className="container">
        <div className="row justify-content-md-between">
          { data?.casesClientes?.nodes?.map((material) => (                         
              <BoxMaterial 
                url={material?.customFieldMateriais?.linkDestino || ""}               
                category={material?.categorias?.nodes?.[0]?.name || ""}
                content={material?.content || ""}                
                title={material?.title || ""}
                thumbUrl = {material?.featuredImage?.node?.sourceUrl || ""} 
                key = {material?.id}
              /> 
            ))         
          }
        </div>
      </section>
      <section className={classNames("container py-md-5 py-3", style.secaoCategoria)}>
        <div className="row">
          <h3>Marketing</h3>
        </div>    
      </section>
      <section className="container">
        <div className="row justify-content-md-between">
          { data?.marketing?.nodes?.map((material) => (                         
              <BoxMaterial 
                url={material?.customFieldMateriais?.linkDestino || ""}               
                category={material?.categorias?.nodes?.[0]?.name || ""}
                content={material?.content || ""}                
                title={material?.title || ""}
                thumbUrl = {material?.featuredImage?.node?.sourceUrl || ""} 
                key = {material?.id}
              /> 
            ))         
          }
        </div>
      </section>
      <NewsletterHome />
      
    </>
  )

}