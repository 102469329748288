import classNames from "classnames";
import { MouseEvent, useEffect, useState } from "react";
import Button from "../Buttons/Buttons";
import style from "./Newsletter.module.scss";

type Props = {
  className?: string;
  model?: "model1" | "model2";
};

export default function Newsletter({ className, model = "model1" }: Props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    console.log(event.target.value);
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    console.log("Nome:", name);
    console.log("Email:", email);
    // Aqui você pode adicionar lógica para enviar os dados para o backend ou API
  };

  return (
    <form
      className={classNames(
        "d-flex flex-column align-items-center",
        className,
        style[model]
      )}
    >
      {/* Campo de nome */}
      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        className="mb-3"
        placeholder="Seu nome"
      />

      {/* Campo de e-mail */}
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        className="mb-3"
        placeholder="Seu e-mail"
      />

      <Button type="submit" onClick={handleSubmit} className={style.cta}>
        Inscreva-se
      </Button>
    </form>
  );
}
