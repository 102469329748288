import { useParams, useSearchParams } from "react-router-dom"
import classNames from "classnames"
import { Dot } from './Dot/Dot'


type Dot = {
  value: number
  label?: number | string  
  active?: string
}

type Props = {
  pages: number
  className?: string
  step?: number
  neighbours?: number
}

export function Pagination(props: Props) {

  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = parseInt(searchParams.get("page") || "1")

  function handlePagination(page: string) {
    setSearchParams({ page })
  }

  /**
   * Gerando os objetos de páginas com label e value
   */
  const dots = generateDots(props.pages)
  const symbols = {
    goToPrevious: '<',
    goToNext: '>',
    goToFirst: '<<',
    goToLast: '>>'
  }

  // Quantidade de números vizinhos para cada lado a partir da página atual
  const neighbours = Math.floor(props.neighbours || 1)

  // Total de números vizinhos + número atual
  const totalNumbers = (neighbours * 2) + 2

  // Total de blocos = Total de números mais blocos de símbolos
  const totalBlocks = totalNumbers + 2
    
  const startPage = Math.max(1, currentPage - neighbours)
  const endPage = Math.min(props.pages - 1, currentPage + neighbours)

  const pages = (() => {

    if( props.pages <= totalBlocks ) {
      return range(dots, 1, props.pages)
    }

    let pages = range(dots, startPage, endPage)

    const hasLeftSpill = startPage > 1
    const hasRightSpill = (props.pages - endPage) > 1
    const spillOffset = totalNumbers - (pages.length + 1)

    switch(true) {
      case(hasLeftSpill && !hasRightSpill): {
        const extraPages = range(dots, startPage - spillOffset, startPage - 1)
        dots[0].label = symbols.goToFirst
        
        pages = [dots[0], ...extraPages, ...pages, dots[props.pages - 1]]
        break
      }

      case(!hasLeftSpill && hasRightSpill): {
        const extraPages = range(dots, endPage + 1, endPage + 1 + spillOffset)
        dots[dots.length - 1].label = symbols.goToLast
        
        pages = [...pages, ...extraPages, dots[props.pages - 1]]
        break
      }

      case(hasLeftSpill && hasRightSpill):
      default: {
        dots[0].label = symbols.goToFirst
        dots[dots.length - 1].label = symbols.goToLast
        pages = [dots[0], ...pages, dots[props.pages - 1]]
        break
      }
    }

    return pages
  
  })()

  return (
    <div className={classNames("d-flex justify-content-center",props.className)}>
      {
         pages.map((dot) => (
          <Dot
            key={`dot-${dot.value}`}
            value={dot?.value}
            label={dot.label}
            active={dot.value === currentPage}
            onClick={(val) => handlePagination(`${val}`)}
          />
        ))
      }     
    </div>
  )
}

/**
 * 
 * @param array Array original do qual será retirado o intervalo
 * @param from Index inicial do intervalo
 * @param to Index final do intervalo
 * @param step Define a sequência da numeração
 * @returns 
 */
function range(array: Dot[], from: number, to: number, step: number = 1) {
  let i = from
  const range: Dot[] = []

  while (i <= to) {
    range.push(array[i - 1])
    i += step
  }

  return range
}

/**
 * Cria um array de páginas
 * 
 * @param length Número total de páginas a serem criadas
 * @param showSymbols Sempre exibe símbolos para as última e primeira posições
 * @returns 
 */
function generateDots(length: number): Dot[] {
  return Array.from({length}, (v, k) => {
    const value = k + 1    
    return {
      value: value,
      label: value
    }
  })
}