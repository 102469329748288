import classNames from 'classnames';
import NewsletterHome from "../components/Newsletter/NewsletterHome";
import BoxPostMain from "../components/Posts/BoxPostMain";
import style from "./Category.module.scss";
import BoxSecondCategory from '../components/Posts/BoxSecondCategory';
import {useGetSearchPostsQuery} from '../shared/generated/graphql';
import { useParams, useSearchParams } from 'react-router-dom';


export function Search() {
    const [searchParams, setSearchParams] = useSearchParams()
    const term = searchParams.get("term")  
  
  const {data} = useGetSearchPostsQuery({
    variables:{size:10, searchTerm:term}
  })
  const featuredPosts = data?.posts?.nodes?.slice(0,4)
  const otherPosts = data?.posts?.nodes?.slice(4,undefined)

  console.log(data)

  return (
    <>
      
      <section className={classNames("container-fluid py-md-5 py-3", style.topo)}>
        <div className="container">
          <div className="row justify-content-md-around">
            <h2>Você buscou por {term}</h2> 
          </div>
        </div>
      </section>
      <section className={classNames("container-fluid py-md-5 py-3", style.mainPosts)}>
        <div className="container">
          <div className="row justify-content-md-around">
          {
            featuredPosts?.map(value => (
              <BoxPostMain
                authorName={value?.author?.node?.name || ""}
                avatarUrl={value?.author?.node?.avatar?.url || ""}
                category={value?.categories?.nodes?.[0]?.name || ""}
                categoryUrl={value?.categories?.nodes?.[0]?.uri || ""}
                content={value?.excerpt || ""}
                date={value?.date || ""}
                title={value?.title || ""}
                key={value?.id || ""}
                linkPost={value?.uri || ""}
              />
            ))
          }  
          </div>
        </div>
      </section>
      <section className={classNames("container-fluid py-md-5 py-3", style.secondPosts)}>
        <div className="container">
          <div className="row justify-content-center">
            {
            otherPosts?.map(value => (
              <BoxSecondCategory
              authorName={value?.author?.node?.name || ""}
              avatarUrl={value?.author?.node?.avatar?.url || ""}
              category={value?.categories?.nodes?.[0]?.name || ""}
              categoryUrl={value?.categories?.nodes?.[0]?.uri || ""}
              content={value?.excerpt || ""}
              date={value?.date || ""}
              title={value?.title || ""}
              key={value?.id || ""}  
              linkPost={value?.uri || ""}            
              />
            ))
          }  
          </div>
        </div>
      </section>
      <NewsletterHome />
      
    </>
  )
}